import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";

const firebaseConfig = {
  apiKey: "AIzaSyBCH3Pf_JOZFZ3htX5vR-vPRj83DR5nu_Q",
  authDomain: "fanspot-a4a50.firebaseapp.com",
  projectId: "fanspot-a4a50",
  storageBucket: "fanspot-a4a50.appspot.com",
  messagingSenderId: "376274774396",
  appId: "1:376274774396:web:af4b2a4cf2211a81115cf9",
  measurementId: "G-7831WN1947"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();
export const firestore = firebase.firestore();
export const storageRef = firebase.storage();
