import React, { Suspense, lazy } from "react";
import { Route, Routes } from "react-router-dom";
import '../pages/Client/style.css'
const Landing = lazy(() => import("../pages/Client/LandingPage"));
const Buisness = lazy(() => import("../pages/Client/Buisness"));
const Pricing = lazy(() => import("../pages/Client/Pricing"));

const ClientRoutes = () => {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/business-pricing" element={<Pricing />} />
        <Route path="/buisness" element={<Buisness />} />
      </Routes>
    </Suspense>
  );
};

export default ClientRoutes;
