import React, { Suspense, lazy } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Sidebar from "../components/sidebar";
import useStore from "../store/store";

const Dashboard = lazy(() => import("../pages/Portal/Dashboard").then(module => ({ default: module.Dashboard })));
const Profile = lazy(() => import("../pages/Portal/Profile").then(module => ({ default: module.Profile })));
const Register = lazy(() => import("../pages/Portal/Register").then(module => ({ default: module.Register })));
const ForgotPassword = lazy(() => import("../pages/Portal/ForgotPassword").then(module => ({ default: module.ForgotPassword })));
const Login = lazy(() => import("./../pages/Portal/Login/index").then(module => ({ default: module.Login })));
const Fixtures = lazy(() => import("./../pages/Portal/fixtures").then(module => ({ default: module.Fixtures })));
const AdminMessages = lazy(() => import("./../pages/Portal/AdminMessages").then(module => ({ default: module.AdminMessages })));
const TableManagement = lazy(() => import("../pages/Portal/table-management").then(module => ({ default: module.TableManagement })));
const Offers = lazy(() => import("./../pages/Portal/Offers/index").then(module => ({ default: module.Offers })));
const OfferForm = lazy(() => import("./../pages/Portal/Offers/OfferForm").then(module => ({ default: module.OfferForm })));
const SocialManagement = lazy(() => import("../pages/Portal/SocialManagement").then(module => ({ default: module.SocialManagment })));
const Reviews = lazy(() => import("../pages/Portal/Reviews").then(module => ({ default: module.Reviews })));
const Settings = lazy(() => import("../pages/Portal/Settings").then(module => ({ default: module.Settings })));
const Memberships = lazy(() => import("../pages/Portal/Memebership").then(module => ({ default: module.Memberships })));
const ContactUs = lazy(() => import("../pages/Portal/ContactUs").then(module => ({ default: module.ContactUs })));
const Statistics = lazy(() => import("../pages/Portal/Statistics").then(module => ({ default: module.Statistics })));

const PortalRoutes = () => {
  const { locale } = useStore((st) => st);
  let portal = sessionStorage.getItem("fanspot-portal");
  portal = portal && JSON.parse(portal);

  const logoutHandler = () => {
    const c = window.confirm(locale.are_you_sure_to_logout);
    if (!c) return;
    sessionStorage.removeItem("fanspot-portal");
    window.location.replace("/portal");
  };

  return !portal ? (
    <Routes>
      <Route path="/portal" element={<Navigate to="/portal/login" />} />
      <Route path="/portal/forgot-password" element={<ForgotPassword />} />
      <Route path="/portal/login" element={
        <Suspense fallback={<div>Loading...</div>}>
          <Login />
        </Suspense>
      } />
      <Route path="/portal/register" element={
        <Suspense fallback={<div>Loading...</div>}>
          <Register />
        </Suspense>
      } />
    </Routes>
  ) : (
    <Sidebar logoutHandler={logoutHandler}>
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route path="/portal" element={<Navigate to="/portal/dashboard" />} />
          <Route path="/portal/dashboard" element={<Dashboard />} />
          <Route path="/portal/profile" element={<Profile />} />
          <Route path="/portal/fixtures" element={<Fixtures />} />
          <Route path="/portal/offers" element={<Offers />} />
          <Route path="/portal/stats" element={<Statistics />} />
          <Route path="/portal/offers/add" element={<OfferForm />} />
          <Route path="/portal/booking-management" element={<TableManagement />} />
          <Route path="/portal/social-management" element={<SocialManagement />} />
          <Route path="/portal/reviews" element={<Reviews />} />
          <Route path="/portal/settings" element={<Settings />} />
          <Route path="/portal/memberships" element={<Memberships />} />
          <Route path="/portal/contactus" element={<ContactUs />} />
          <Route path="/portal/inbox" element={<AdminMessages />} />
        </Routes>
      </Suspense>
    </Sidebar>
  );
};

export default PortalRoutes;
