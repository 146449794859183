import React from "react";
import { BrowserRouter } from "react-router-dom";

// DASHBOARD IMPORTS
import AdminRoutes from "./AdminRoutes";
import PortalRoutes from "./PortalRoutes";
import ClientRoutes from "./ClientRoutes";
import useStore from "../store/store";
import { getLocale } from "../Firebase/apis";
import Spinner from "../components/common/Spinner.jsx";

const appLocale = sessionStorage.getItem("fanspot-locale");

function Index() {
  const [loading, setLoading] = React.useState(true);
  const { setAppLocale, setLocale, setLanguage } = useStore((st) => st);
  React.useEffect(() => {
    getLocale()
      .then((data) => {
        setAppLocale(data.arrabicLocale, data.englishLocale);

        if (window.location.pathname.includes("/admin")) {
          setLocale(
            data.englishLocale
          );
          setLanguage("English");
        } else {
          setLocale(
            appLocale && appLocale.includes("English")
              ? data.englishLocale
              : data.arrabicLocale
          );
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, []);
  if (loading) {
    return <Spinner />;
  }

  return (
    <BrowserRouter>
      <ClientRoutes />
      <PortalRoutes />
      <AdminRoutes />
    </BrowserRouter>
  );
}

export default Index;
